import React from 'react';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { useTranslation } from 'react-i18next';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks/useSettings';
import AntifraudWidget from './widgets/AntifraudWidget/AntifraudWidget';

const Antifraud: React.FC = ({}) => {
    const { t } = useTranslation();
    const { user } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();

    return (
        <ModuleWrapper>
            <WrapperContainer style={{ maxWidth: 'none' }}>
                <AntifraudWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Antifraud;
