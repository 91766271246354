import { Identity } from 'src/types';
import { capitalizeFirst } from 'src/tools';
import {
    structuresComparer,
} from 'src/tools';
import { ColumnOptions, FocusTable } from 'src/components/FocusTable';
import {
    SENSOR_STATUSES,
    OPERATION_ORDER,
} from 'src/Chapters/Services/FpcMonitoring/constants';
import {
    OperationsTypes,
} from 'src/Chapters/Services/FpcMonitoring/interfaces';

import { StatusCircle } from '../PcSensorsInfo/styles';

import { CenteredCell } from './styles';

const detailedColumns: ColumnOptions[] = [
    { id: 'id', label: 'ID' },
    { id: 'floor', label: 'Floor', align: 'right', sortOrder: 'desc' },
    { id: 'type', label: 'Type' },
    { id: 'mac', label: 'Serial (MAC)', align: 'right' },
    { id: 'marker', label: 'IPoint marker' },
    {
        id: 'status',
        label: 'Data status',
        hasSort: true,
        hasFilter: false,
        align: 'center',
        verticalAlign: 'middle',
        sortComparer: structuresComparer(OPERATION_ORDER),
        valueFormatter: (value: string, context) => capitalizeFirst(context?.t(value)),
        cellRenderer: (operation: OperationsTypes) => {
            const fill = SENSOR_STATUSES[operation].fill;
            return (
                <CenteredCell>
                    <StatusCircle fill={fill} />
                </CenteredCell>
            );
        },
    },
    { id: 'perimeterRelations', label: 'Perimeter relations' },
    { id: 'passwaysRelations', label: 'Passways relations' },
    { id: 'tenantsRelations', label: 'Tenants relations' },
    { id: 'zonesRelations', label: 'Zones relations' },
];

export const DetailedTable = ({ detailedData }: { detailedData: Identity<string>[] }) => {
    return (
        <FocusTable
            componentId="DetailedSensors"
            value={detailedData}
            columnsOptions={detailedColumns}
            wrapperStyle={{ maxHeight: '80vh' }}
        />
    );
};
