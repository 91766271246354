import { useMemo } from 'react';

import { useGeneralSelector, useUniversalDateTimePickerPeriod } from 'src/hooks';
import { useFetchStructures, STRUCTURE_MAP } from 'src/tools/API';
import {
    emptyObject,
    filterValidDateRanges,
    transformFields,
    // camelToSnakeCase,
    snakeToCamelCase,
} from 'src/tools';

type MapStructure = object & { dataobjType: string };
type MapStructuresRecord = Record<string, Array<MapStructure>>;

export const useActualStructures = () => {
    const { selectedLocationId } = useGeneralSelector();
    const period = useUniversalDateTimePickerPeriod();

    const plId = selectedLocationId || undefined;

    const { data: sensorRelativesData, isLoading, isError } = useFetchStructures();

    const actualStructures = useMemo(() => {
        if (!sensorRelativesData || !plId) {
            return undefined;
        }

        const filteredStructures = {};

        STRUCTURE_MAP.forEach((section, structureType) => {
            const resultName = `pl_structure/pl${plId}/${section}/${structureType}.json`;
            const items = (sensorRelativesData?.data?.[resultName] || []) as Array<any>;

            // Filter items based on date ranges or ignore filtration
            const filteredItems = filterValidDateRanges(items, period, true);
            filteredStructures[structureType] = filteredItems;
        });

        const transformedStructures = transformFields<MapStructuresRecord>(
            filteredStructures,
            snakeToCamelCase,
        );

        return transformedStructures;
    }, [sensorRelativesData, plId, period]);

    return {
        actualStructures,
        isLoading,
        isError,
    };
};