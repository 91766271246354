import { useMemo, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useGeneralSelector } from 'src/hooks';
import { IStructureQuery } from 'src/tools/API/interfaces';
import requestStructure from 'src/tools/API/requestStructure';
import { transformFields, camelToSnakeCase } from 'src/tools';

export const STRUCTURE_MAP = new Map<string, string>([
    ['relations_tenant2place', 'core'],
    ['relations_dataobj2floor', 'core'],
    ['elements_admin_data_objects', 'core'],
    ['elements_geo_objects', 'core'],
    ['relations_tenant2zone', 'core'],
    ['relations_tenant2floor', 'core'],
    ['relations_passway2dataobj', 'core'],
    ['relations_tenant2location', 'core'],
    ['relations_place2zone', 'core'],
    ['elements_ms_data_objects', 'core'],
    ['elements_pc_ipoints', 'fpc'],
    ['elements_pc_sensors', 'fpc'],
    ['relations_pcipoint2passway', 'fpc'],
    ['relations_sensor2dataobj', 'fpc'],
    ['relations_sensor2passway', 'fpc'],
    ['relations_sensor2pcipoint', 'fpc'],
]);

export const useFetchStructures = () => {
    // const signalRef = useRef<AbortSignal>();
    const { selectedLocationId, urlsByServices, token } = useGeneralSelector();
    const structureUrl = urlsByServices?.['core/structure-service']?.CACHED_STRUCTURE_URL;

    // useEffect(() => {
    //     const controller = new AbortController()
    //     signalRef.current = controller.signal;
    //     return () => controller.abort('Unmount component')
    // }, []);

    // const signal = signalRef.current;

    const mainRequest = useMemo(() => {
        const plId = selectedLocationId || undefined;

        const mappedQueries = Array.from(STRUCTURE_MAP.entries())
            .map(([structureType, structureSection]) => ({
                plId,
                structureSection,
                structureType,
            }));

        const transformedQueries = transformFields<IStructureQuery[]>(mappedQueries, camelToSnakeCase);

        return {
            // signal,
            token,
            url: structureUrl,
            queries: transformedQueries,
        };
    }, [selectedLocationId, structureUrl, token]);

    const queryKey = ['fullStructures', selectedLocationId];

    return useQuery({
        queryKey,
        queryFn: () => requestStructure(mainRequest),
        enabled: !!structureUrl && !!selectedLocationId,
    });
};