import styled from 'styled-components';

// Styled Components
export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  height: 40vh;
  overflow-y: auto;

  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-border-gray);
`;

export const ListItem = styled.li<{ isSelected?: boolean}>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  margin: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-border-gray);
  gap: 10px;

  align-items: center;
  margin-bottom: 5px;
  padding: 2px;
  border-radius: 5px;
  background-color: ${(p) => p.theme.colors.secondaryLight};
  font-weight:  ${(p) => p.isSelected ? 'bold' : ''};
`;

export const StatusCircle = styled.span<{ fill: string }>`
  width: 16px;
  height: 16px !important;
  border-radius: 50%;
  margin: auto;
  background-color: ${(props) => (props.fill)};
  display: inline-block;
`;

export const StatusText = styled.span`
  font-size: 14px;
`;