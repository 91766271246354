import { NO_SENSOR_OPERATIONS, PARTIAL_SENSOR_OPERATIONS, FULL_SENSOR_OPERATIONS } from '../../../constants';

export type PcSensorDescription = {
    fill: string;
    operation: string
}

export const describeSensor = (values: Array<{ v: number }>, maxMeasures: number): PcSensorDescription => {
    const averageMeasure =
        values.reduce((acc, val) => {
            return acc + val.v;
        }, 0) / maxMeasures;

    if (averageMeasure === 0) {
        return NO_SENSOR_OPERATIONS;
    }
    if (averageMeasure < 0.999999 && averageMeasure > 0) {
        return PARTIAL_SENSOR_OPERATIONS;
    }
    return FULL_SENSOR_OPERATIONS;
}