import styled from 'styled-components/macro';

export const StructuresWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 20px;
`;
export const TableWrapper = styled.div`
    /* flex-grow: 0;
    flex-shrink: 1; */
    overflow: auto;
`;

export const TreeWrapper = styled.div`
    margin-left: auto;
    width: 300px;
    /* position: sticky;
    right: 0;
    top: 0; */
    /* flex-grow: 1;
    flex-shrink: 0; */
`;
