import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import Title from '../../../../../components/Title/Title';
import Stack from '../../../../../components/Stack/Stack';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import HistoryTable from './components/HistoryTable/HistoryTable';
import { useFetchHistory } from './hooks/useFetchHistory';
import LoadingBox from 'src/components/LoadingBox/LoadingBox';
import { Description, Footer } from './styles';
import { Button } from 'src/components/Button';
import Modals from 'src/components/Modals/Modals';
import FilesModal from 'src/Chapters/Reports/components/FilesModal/FilesModal';

const SAMPLE_REPORT_URL = `https://storage.yandexcloud.net/shared-reports/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%20%D0%90%D0%BD%D1%82%D0%B8%D1%84%D1%80%D0%BE%D0%B4%20RPVIS.pdf`;

const AntifraudWidget: React.FC = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);

    const { data, error, isLoading } = useFetchHistory();

    const [filesModalStatus, setFilesModalStatus] = useState<{ show: boolean; files: string[] }>({
        show: false,
        files: [],
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const title = `${t('Antifraud reports history')}`;

    const openFilesModal = () => {
        setFilesModalStatus({ show: true, files: [SAMPLE_REPORT_URL] });
    };

    const closeFilesModal = () => {
        setFilesModalStatus({ show: false, files: [] });
    };

    return isLoading ? (
        <LoadingBox height={400} text={t('Loading...')} />
    ) : data?.length ? (
        <WidgetWrapper styles={{ marginBottom: 0, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Stack justifyContent="space-between" alignItems="center" gap={10}>
                <WidgetTitle>
                    <Title>{title}</Title>
                </WidgetTitle>
            </Stack>
            <HistoryTable />
        </WidgetWrapper>
    ) : (
        <WidgetWrapper styles={{ marginBottom: 0, display: 'flex', flexDirection: 'column' }}>
            <Stack justifyContent="space-between" alignItems="center" gap={10}>
                <WidgetTitle>
                    <Title>{t('Antifraud reports')}</Title>
                </WidgetTitle>
            </Stack>
            <Description>
                <p>{t('In this section, you can download the Antifraud Report template.')}</p>
                <p>
                    {t(
                        `All clients with the Focus Sales Flow product have access to the 'Antifraud Management' service, where we use programming and AI to analyze tenants for sales anomalies and underreporting techniques.`,
                    )}
                </p>
                <p>{t('As a result, you will receive the amount of potentially lost revenue for each tenant.')}</p>
                <p>
                    {`${t('If you would like to order a report, please send an email.')} `}
                    <address>
                        <a href="mailto:antifraud@focus.bi">antifraud@focus.bi</a>
                    </address>
                </p>
            </Description>
            <Footer>
                <Button onClick={openFilesModal} appearance="primary" text={t('Template')} />
            </Footer>
            <Modals closeModal={closeFilesModal} modalStatus={filesModalStatus} title={t('Report files')}>
                <FilesModal files={filesModalStatus.files} />
            </Modals>
        </WidgetWrapper>
    );
};

export default AntifraudWidget;
