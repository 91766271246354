import { KonvaEventObject } from 'konva/lib/Node';
import React, { useMemo } from 'react';
import { Group, Circle } from 'react-konva';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { theme } from 'src/theme';
// import { findLabelCoords } from 'src/tools/findLabelCoords';
import { scaleTransformFoo } from 'src/tools/scaleTransformFoo';
// import { ObjectLabel } from 'src/components/ObjectLabel/ObjectLabel';
// import { getArrowPoint } from 'src/Chapters/Services/FpcMonitoring/widgets/MonitoringWidget/core/getArrowPoint';
import { storeSelectedSensorMarker } from 'src/Chapters/Services/FpcMonitoring/reducer';

import { PcSensorProps } from './interfaces';

export const PcSensor: React.FC<PcSensorProps> = ({
    object,
    stageScale,
    overMarker,
    heatColor,
    onMouseOverLeave,
    followedBy,
}) => {

    // const { storeSelectedSensorId } = useSelector(FPC_Monitoring_Module_Reducer_Values);
    const dispatch = useDispatch();
    const selectObject = bindActionCreators(storeSelectedSensorMarker, dispatch)

    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        // e.cancelBubble = true;
        selectObject?.(object.marker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        // if e.cancelBubble = true;
        onMouseOverLeave?.(object.marker);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        // e.cancelBubble = true;
        onMouseOverLeave?.(null);
    };

    const mainRadius = scaleTransformFoo(15, stageScale);
    const outerRadius = scaleTransformFoo(20, stageScale);
    const radius = scaleTransformFoo(4, stageScale);
    const offsetDistance = scaleTransformFoo(6, stageScale);
    const centralFill = heatColor;

    const [centerX, centerY] = object.centerPoint.coordinates;
    const key = object?.marker ?? 'GroupPcSensor!@##11';

    return (
        <Group
            id={key}
            key={key}
            onClick={onObjectClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <Circle
                x={centerX}
                y={centerY}
                radius={outerRadius}
                fill={theme.colors.white}
            />
            <Circle
                x={centerX}
                y={centerY}
                radius={mainRadius}
                fill={centralFill}
            />

            <Circle
                x={centerX - offsetDistance}
                y={centerY}
                radius={radius}
                fill={'white'}
            />
            <Circle
                x={centerX + offsetDistance}
                y={centerY}
                radius={radius}
                fill={'white'}
            />
        </Group>
    );
};
