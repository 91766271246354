import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Shape } from 'react-konva';

import { PLACES_OPACITY, PLACES_COLOR, UNIDENTIFIED_LAYER_COLOR } from 'src/constants';

import { Props } from './interfaces';

const Place: React.FC<Props> = ({
    object,
    selectObject,
    selectedMarker,
    onMouseOverLeave,
    heatColor,
    reportingObjectsByTypeAndMarker,
    layerType = 'places_layer',
    stageIndex,
    opacity = PLACES_OPACITY,
    changeStageCursor,
}) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject(object.marker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(object.marker);

        if (changeStageCursor && layerType === 'tenants_layer' && !object.marker) {
            changeStageCursor(stageIndex, 'not-allowed')();
        } else if (changeStageCursor && layerType === 'tenants_layer' && object.marker) {
            changeStageCursor(stageIndex, 'pointer')();
        }
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(null);
        changeStageCursor && changeStageCursor(stageIndex, 'pointer')();
    };

    const fllColor = heatColor ?? PLACES_COLOR;
    const fill = selectedMarker !== object?.marker ? fllColor : UNIDENTIFIED_LAYER_COLOR;

    const coordPair = object?.coords?.coordinates?.[0] ;

    if (!coordPair) {
        return null;
    }
    const [startX, startY] = coordPair;

    const key = object?.marker ?? 'ShapePlace!@##11';

    return (
        <Shape
            id={key}
            key={key}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onObjectClick}
            onTap={onObjectClick}
            // data-target-id={`polygon_${object?.marker}_${metric}`}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(startX, startY);
                object.coords.coordinates.forEach(([x, y]) => context.lineTo(x, y));
                context.fillStrokeShape(shape);
            }}
            opacity={opacity}
            // stroke={'blue'}
            fill={fill}
            strokeWidth={2}
        />
    );
};

export default Place;
