import { DateTime } from 'luxon';

import { TLang } from '../../General.interfaces';

const ss = (isoDate: string | undefined, lang: TLang, preset: string) => {
    return DateTime.fromISO(isoDate || '')
        .setLocale(lang)
        .toLocaleString(DateTime[preset]);
};

const sf = (isoRange: string[] | undefined, lang: TLang, preset: string) => {
    if (!isoRange) return '';

    const from = DateTime.fromISO(isoRange[0]);
    const to = DateTime.fromISO(isoRange[1]);

    let formattedFrom: string = '';

    if (from.year === to.year) {
        if (from.month === to.month) {
            if (lang !== 'en') {
                formattedFrom = from.setLocale(lang).toLocaleString({ day: 'numeric' });
            } else {
                const month = from.setLocale(lang).toLocaleString({ month: 'short' });
                const dayFrom = from.setLocale(lang).toLocaleString({ day: 'numeric' });
                const dayTo = to.setLocale(lang).toLocaleString({ day: 'numeric' });
                const year = to.setLocale(lang).toLocaleString({ year: 'numeric' });
                return `${month} ${dayFrom} – ${dayTo}, ${year}`;
            }
        } else {
            formattedFrom = from.setLocale(lang).toLocaleString({ day: 'numeric', month: 'short' });
        }
    } else {
        formattedFrom = ss(isoRange[0], lang, preset);
    }

    return `${formattedFrom} – ${ss(isoRange[1], lang, preset)}`;
};

const isObjectRange = (args: any) => {
    return (
        args?.hasOwnProperty('dateFrom') &&
        args?.hasOwnProperty('dateTo') &&
        typeof args['dateFrom'] === 'string' &&
        typeof args['dateTo'] === 'string'
    );
};

/** Функция для получения даты короткого формата */
export function shortStringDate(
    range: string | { dateFrom: string; dateTo: string } | string[] | undefined,
    lang: TLang,
    preset: string = 'DATE_MED',
): string {
    if (isObjectRange(range)) {
        if (range && typeof range === 'object' && !Array.isArray(range) && range?.dateFrom !== range.dateTo) {
            return sf([range.dateFrom, range.dateTo], lang, preset);
        } else if (range && typeof range === 'object' && !Array.isArray(range) && range?.dateFrom === range.dateTo) {
            return `${ss(range.dateFrom, lang, preset)}`;
        } else return '';
    } else if (typeof range === 'string') {
        return ss(range, lang, preset);
    } else if (Array.isArray(range)) {
        if (range[0] !== range[1]) {
            return sf(range, lang, preset);
        } else if (range[0] === range[1]) {
            return `${ss(range[0], lang, preset)}`;
        } else return '';
    } else return '';
}
