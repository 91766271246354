import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IColorsByMarker, IReducerState } from './interfaces';

import { ILayer, IPlan, IReportingObject, IVersion, TLoadingData } from '../../../General.interfaces';

const initialState: IReducerState = {
    moduleName: 'Maps:Maps overview',
    layers: [],
    selectedMarker: null,
    overMarker: null,
    selectedVersionId: null,
    selectedRelationId: 'all',
    selectedObjectType: 'tenant',
    zonesByGroup: {},
    selectedGroupId: null,
    selectedZoneId: null,
    selectedPlaceId: null,
    selectedSensorMarker: null,
    metricsData: { message: 'Loading', status: 'Loading' },
    colorsByMarker: null,
};

export const FPC_Monitoring_Module_Reducer = createSlice({
    name: 'FPC_Monitoring_Module_Reducer',
    initialState,

    reducers: {
        storeLayers: (state, action: PayloadAction<ILayer[]>) => {
            state.layers = action.payload;
        },
        storeSelectedMarker: (state, action: PayloadAction<string | null>) => {
            state.selectedMarker = action.payload;
        },
        storeOverMarker: (state, action: PayloadAction<string | null>) => {
            state.overMarker = action.payload;
        },
        storeObjectsType: (state, action: PayloadAction<string>) => {
            state.selectedMarker = null;
            state.selectedObjectType = action.payload;
        },
        storeZonesByGroup: (state, action: PayloadAction<{ [x: string]: IReportingObject[] }>) => {
            state.zonesByGroup = action.payload;
        },
        storeSelectedGroup: (state, action: PayloadAction<string | null>) => {
            state.selectedGroupId = action.payload;
        },
        storeSelectedZoneId: (state, action: PayloadAction<string | null>) => {
            state.selectedZoneId = action.payload;
        },
        storeSelectedSensorMarker: (state, action: PayloadAction<string | null>) => {
            state.selectedSensorMarker = action.payload;
        },
        storeSelectedPlaceId: (state, action: PayloadAction<number | null>) => {
            state.selectedPlaceId = action.payload;
        },
        storeInitialData: (state, action: PayloadAction<TLoadingData>) => {
            state.metricsData = action.payload;
        },
        storeColorData: (state, action: PayloadAction<IColorsByMarker | null>) => {
            state.colorsByMarker = action.payload;
        },
        storeSelectedVersionId: (state, action: PayloadAction<number>) => {
            state.selectedVersionId = action.payload;
        },
        storeSelectedRelationId: (state, action: PayloadAction<string>) => {
            state.selectedRelationId = action.payload;
        },
    },
});

export const {
    storeSelectedVersionId,
    storeSelectedRelationId,
    storeSelectedPlaceId,
    storeOverMarker,
    storeLayers,
    storeSelectedMarker,
    storeObjectsType,
    storeZonesByGroup,
    storeInitialData,
    storeSelectedGroup,
    storeColorData,
    storeSelectedZoneId,
    storeSelectedSensorMarker,
} = FPC_Monitoring_Module_Reducer.actions;

export const FPC_Monitoring_Module_Reducer_Values = (state: RootState) => state.FPC_Monitoring_Module_Reducer;

export default FPC_Monitoring_Module_Reducer.reducer;
