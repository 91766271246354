import React, { useCallback, useEffect, useRef, useState, useMemo, SetStateAction, Dispatch } from 'react';
import ReactDataSheet from 'react-datasheet';
import { TFunction } from 'i18next';

import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { emptyArray } from 'src/tools';
import { Identity } from 'src/types';

import {
    FilterUpdater,
    Cell,
    ColumnOptions,
    FocusTableProps,
    TableFilter,
    TableContext,
    TableConfigProps,
    ExternalTableContextProps,
    ColumnSorter,
    SorterUpdater,
    TableConfig,
} from './interfaces';
import { HeaderCell } from './components/HeaderCell';
import { getLabel, prepareFiltersByColumns } from './utils';
import { UNSELECTED } from './constants';
import { StyledHeaderCell } from './styles';
import { theme } from 'src/theme';

type OmittedProps = 'valueRenderer' | 'menuComponent' | 'data';

const getInitialContext = <
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
>({
    processData,
    componentId,
    nameForExports,
    setContextMenuEvent,
    initialConfig,
    onChangeConfig,
    t,
}: {
    processData: React.Dispatch<React.SetStateAction<[]>>;
    setContextMenuEvent: React.Dispatch<React.SetStateAction<MouseEvent | null>>;
    t: TFunction;
} & ExternalTableContextProps &
    TableConfigProps) => {
    const sorters = initialConfig?.sorters || ([] as ColumnSorter[]);

    const changeSorter = (newValueOrUpdater: ColumnSorter[] | SorterUpdater) => {
        const newSorters =
            typeof newValueOrUpdater === 'function' ? newValueOrUpdater([...filters]) : [...newValueOrUpdater];
        sorters.splice(0, sorters.length);
        sorters.unshift(...newSorters);
        processData([]);
        onChangeConfig?.({
            componentId,
            sorters: [...sorters],
        });
    };

    const filters = initialConfig?.filters || ([] as TableFilter[]);

    const changeFilter = (newValueOrUpdater: TableFilter[] | FilterUpdater) => {
        const newFilters =
            typeof newValueOrUpdater === 'function' ? newValueOrUpdater([...filters]) : [...newValueOrUpdater];
        filters.splice(0, filters.length);
        filters.unshift(...newFilters);
        processData([]);
        onChangeConfig?.({
            componentId,
            filters: [...filters],
        });
    };

    return {
        sorters,
        changeSorter: (updaterOrSorters: ColumnSorter[] | SorterUpdater) => {
            changeSorter(updaterOrSorters);
        },
        filters,
        changeFilter: (updaterOrFilters: TableFilter[] | FilterUpdater) => {
            changeFilter(updaterOrFilters);
        },

        setContextMenuEvent,
        closeMenu: () => setContextMenuEvent(null),

        visibleColumns: emptyArray as ColumnOptions<I, R>[],
        data: emptyArray as R[],
        rawData: emptyArray as R[],

        componentId,
        nameForExports: nameForExports ?? componentId,
        t,
    };
};

const defaultCellStyle = {
    padding: theme.spaces.xxxxm,
};

export function usePrepareTableProps<I extends string, R extends Identity<I> = Identity<I>>({
    columnsOptions = emptyArray,
    // menuConfig,
    componentId,
    nameForExports,
    initialConfig,
    onChangeConfig,
    selectedCells,
    onSelectionChange,
    cellStyle = defaultCellStyle,
    ...restProps
}: Omit<FocusTableProps<I, R>, OmittedProps>) {
    const { t } = useTranslation();

    // some trick with processData to rerender component after any filter changes
    const [needProcessData, processData] = useState<[]>([]);
    const [selected, setSelected] = useState<ReactDataSheet.Selection | null>(null);
    const [contextMenuEvent, setContextMenuEvent] = useState<MouseEvent | null>(null);

    const columnWithSort = columnsOptions.find(({ sortOrder }) => sortOrder !== undefined);
    const initialSorters = {
        ...(columnWithSort &&
            ({
                sorters: [
                    {
                        sortOrder: columnWithSort.sortOrder,
                        columnId: columnWithSort.id,
                    },
                ],
            } as TableConfig)),
    };

    const initialContext = getInitialContext<I, R>({
        processData,
        setContextMenuEvent,
        componentId,
        nameForExports,
        initialConfig: initialConfig || initialSorters,
        onChangeConfig,
        t,
    });

    const tableContextRef = useRef<TableContext<I, R>>(initialContext);

    const onContextMenu = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            setContextMenuEvent({ ...event });
        },
        [setContextMenuEvent],
    );
    const onMouseDown = useCallback((event: MouseEvent) => {
        // to prevent display browser's menu when context menu is opened
        tableContextRef.current.closeMenu(event);
    }, []);

    const [visibleColumnIds, visibleColumns] = useMemo(() => {
        const invisibleIds = columnsOptions.reduce(
            (acc, column: ColumnOptions<I, R>) => (column.visible === false ? acc : [...acc, column.id]),
            [],
        );
        const columns = columnsOptions
            .filter((col: ColumnOptions<I, R>) => col.visible !== false)
            .map((col) => (cellStyle ? { ...col, style: { ...cellStyle, ...col.style } } : col));
        // .map(({ align: texAlignLast, ...col }) => cellStyle ? ({ ...col, style: { texAlignLast, ...cellStyle, ...col.style } }) : col);

        tableContextRef.current.visibleColumns = columns;

        return [invisibleIds, columns];
    }, [columnsOptions, cellStyle]);

    const data = restProps?.value || (emptyArray as R[]);

    const filters = tableContextRef.current.filters;
    const filtersRecord = useMemo(() => {
        return prepareFiltersByColumns(filters) || needProcessData;
    }, [filters, needProcessData]);

    const filteredTableData = useMemo(() => {
        const filteredRows = data.filter((row) => {
            return Object.keys(filtersRecord).every((columnId) => {
                const columnFilters = filtersRecord[columnId];
                const value = row[columnId];
                return columnFilters.every(({ filterPredicate }) => filterPredicate(value));
            });
        });

        const sortedRows = [...filteredRows].sort((a, b) => {
            for (const sorter of tableContextRef.current.sorters) {
                const { columnId, sortOrder } = sorter;
                const aValue = a[columnId];
                const bValue = b[columnId];

                const sortDirection = sortOrder === 'asc' ? -1 : 1;
                const comparer = visibleColumns.find(({ id }) => id === columnId)?.sortComparer;
                const order = comparer
                    // Compare values with customComparer
                    ? comparer(aValue, bValue, tableContextRef.current)
                    :(aValue > bValue) ? -1 : 1;
                return order * sortDirection;
            }
            // If all sorters are equal, keep original order
            return 0;
        });
        // .splice(0, 10); // keep it or fast test

        tableContextRef.current.data = sortedRows;

        return sortedRows.reduce(
            (acc, row) => [
                ...acc,
                visibleColumnIds.map((columnId) => {
                    // return row[colName];
                    return { value: row[columnId.toString()] } as Cell<Cell<any, any>, string>;
                }),
            ],
            [] as Cell<Cell<any, any>, string>[][],
        );
    }, [filtersRecord, visibleColumns, visibleColumnIds, data]);

    const headerCellRenderer = useCallback(
        ({
            id,
            label,
            sortOrder,
            hasSort = true,
            isCancellableSort,
            style,
        }: ColumnOptions<I, R>) => {
            const isColumnHasSort = Boolean(sortOrder) || hasSort !== false;
            return (
                <StyledHeaderCell key={id} style={style}>
                    <HeaderCell
                        hasSort={isColumnHasSort}
                        columnId={id}
                        tableContextRef={tableContextRef}
                        isCancellableSort={isCancellableSort}
                    >
                        {getLabel(id, label, t)}
                    </HeaderCell>
                </StyledHeaderCell>
            );
        },
        [t],
    );

    useEffect(() => {
        const externalChangeSelection = (selection: ReactDataSheet.Selection | null) => {
            if (!selection) {
                setTimeout(() => onSelectionChange?.(null, emptyArray), 0);
                return;
            }

            const selectedStart = selection.start.i;
            const selectedCount = selection.end.i - selectedStart + 1;
            const tableData = [...tableContextRef.current.data];
            const selectedRows = tableData.splice(selectedStart, selectedCount);

            const tableRange = {
                start: { row: selectedStart, col: selection.start.j },
                end: { row: selection.end.i, col: selection.end.j },
            };
            setTimeout(() => onSelectionChange?.(tableRange, selectedRows), 0);
        };
        const localChangeSelection = (selection: ReactDataSheet.Selection | null) => {
            setTimeout(() => setSelected(selection || null), 0);
        };

        tableContextRef.current.changeSelection = onSelectionChange ? externalChangeSelection : localChangeSelection;
    }, [onSelectionChange]);

    useEffect(() => {
        const handleMouseUp = () => {
            const { changeSelection, selection, isSelection } = tableContextRef.current;

            // to prevent handle changeSelection for other tables
            if (isSelection) {
                tableContextRef.current.isSelection = false;
                changeSelection?.(selection || null);
            }
        };
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        if (selectedCells === null) {
            tableContextRef.current.selection = UNSELECTED;
            setSelected(UNSELECTED);
            return;
        }

        if (selectedCells) {
            const { start, end } = selectedCells;
            const selection = {
                start: { i: start.row, j: start.col },
                end: { i: end.row, j: end.col },
            };
            tableContextRef.current.selection = selection;
            setSelected(selection);
        }
    }, [selectedCells]);

    const onSelect = useCallback(
        (range: ReactDataSheet.Selection | null) => {
            if (!range) {
                tableContextRef.current.selection = null;
                setTimeout(() => setSelected(null), 0);
                return;
            }
            tableContextRef.current.selection = range;
            tableContextRef.current.isSelection = true;

            setTimeout(() => setSelected(range), 0);
        },
        [],
    );

    return {
        ...restProps,

        tableContextRef,
        columnsOptions: visibleColumns,
        headerCellRenderer,

        onMouseDown,
        onContextMenu,
        contextMenuEvent,

        data: filteredTableData,

        selected,
        ...((!!onSelectionChange || selectedCells !== null) && { onSelect }),
    };
}
