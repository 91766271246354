import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Group, Shape } from 'react-konva';

import { ZONES_OPACITY, ZONES_COLOR, UNIDENTIFIED_LAYER_COLOR } from 'src/constants';

import { scaleTransformFoo } from '../../../../../../../../../tools/scaleTransformFoo';

import { Props } from './interfaces';

const Zone: React.FC<Props> = ({
    object,
    selectObject,
    onMouseOverLeave,
    selectedMarker,
    stageScale,
    heatColor,
    opacity = ZONES_OPACITY,
}) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject(object.zoneMarker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(`${object.zoneMarker}+${object.frontId}`);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(null);
    };

    const fllColor = heatColor ?? ZONES_COLOR;
    const fill = selectedMarker !== object?.zoneMarker ? fllColor : UNIDENTIFIED_LAYER_COLOR;
    const key = object?.zoneMarker ?? 'ShapeZone!@##11';

    const [startX, startY] = object.coords.coordinates[0];

    return (
        <Shape
            id={key}
            key={key}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onObjectClick}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(startX, startY);
                object.coords.coordinates.forEach(([x, y]) => context.lineTo(x, y));
                context.fillStrokeShape(shape);
            }}
            opacity={opacity}
            stroke={'white'}
            fill={fill}
            strokeWidth={scaleTransformFoo(4, stageScale)}
        />
    );
};

export default Zone;
