import { theme } from 'src/theme';

export const FULL_SENSOR_OPERATIONS = {
    fill: theme.colors.success,
    operation: 'full',
} as const;

export const PARTIAL_SENSOR_OPERATIONS = {
    fill: theme.colors.warning,
    operation: 'partial',
} as const;

export const NO_SENSOR_OPERATIONS = {
    fill: theme.colors.accent,
    operation: 'no',
} as const;

export const OPERATION_ORDER = {
    [NO_SENSOR_OPERATIONS.operation]: 1,
    [PARTIAL_SENSOR_OPERATIONS.operation]: 2,
    [FULL_SENSOR_OPERATIONS.operation]: 3,
} as const;

export const SENSOR_STATUSES = {
    [FULL_SENSOR_OPERATIONS.operation]: FULL_SENSOR_OPERATIONS,
    [PARTIAL_SENSOR_OPERATIONS.operation]: PARTIAL_SENSOR_OPERATIONS,
    [NO_SENSOR_OPERATIONS.operation]: NO_SENSOR_OPERATIONS,
};
