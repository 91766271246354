import { theme } from 'src/theme';

export const POLYGON_NEUTRAL_COLOR = 'hsl(210, 21%, 83%)';
export const UNIDENTIFIED_LAYER_COLOR = 'rgba(0,0,255,0.5)';

export const PERIMETER_COLOR = theme.colors.secondaryDark;
export const PERIMETER_OPACITY = 0.7;

export const ZONES_COLOR = theme.colors.tangerine;
export const ZONES_OPACITY = 0.4;

export const PLACES_COLOR = theme.colors.primary;
export const PLACES_OPACITY = 0.6;

export const PASS_WAYS_COLOR = theme.colors.accentLighter;
export const PASS_WAYS_OPACITY = 1;

export const LAYERS_COLORS = {
    'perimeter_layer': PERIMETER_COLOR,
    'zones_layer': ZONES_COLOR,
    'places_layer': PLACES_COLOR,
    'pass_ways_layer': PASS_WAYS_COLOR,
};

export const LAYERS_ORDER = {
    'perimeter_layer': 1,
    'zones_layer': 2,
    'places_layer': 3,
    'pass_ways_layer': 4,
} as const;
