import { useTranslation } from 'react-i18next';
import React from 'react';

import LoadingBox from 'src/components/LoadingBox/LoadingBox';
import { useFetchStructures } from 'src/tools/API';

import { Tree } from './components/Tree/Tree';
import { StructuresWrapper, TableWrapper, TreeWrapper } from './styles';
import { Table } from './components/Table/Table';

export const Structures: React.FC = () => {
    const { t } = useTranslation();
    const { data, isLoading, isError } = useFetchStructures();

    return isLoading ? (
        <LoadingBox height={400} text={t('Loading...')} />
    ) : isError ? (
        <LoadingBox isError height={400} text={t('Error...')} />
    ) : data ? (
        <StructuresWrapper>
            <TableWrapper>
                <Table />
            </TableWrapper>
            <TreeWrapper>
                <Tree />
            </TreeWrapper>
        </StructuresWrapper>
    ) : null;
};
