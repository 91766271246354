import styled from 'styled-components/macro';

export const Footer = styled.div`
    margin-top: var(--space-sm);
    display: flex;
    justify-content: flex-end;
`;

export const Description = styled.div``;

export const TableWrapper = styled.div`
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    padding: var(--space-xxs);
    /* margin-top: var(--space-sm); */
    max-height: 500px;
    overflow: auto;
    table {
        /* width: 100%; */
    }
    td {
        padding: var(--space-xxxs) !important;
        overflow: hidden;
    }
`;

export const Table = styled.div`
    td {
        white-space: nowrap !important;
    }
`;
