import React from 'react';
import {
    Tree as FluentTree,
    TreeItem,
    TreeItemValue,
    TreeOpenChangeData,
    TreeOpenChangeEvent,
} from '@fluentui/react-components';
import { AddSquare16Regular, SubtractSquare16Regular, TableFreezeColumnRegular } from '@fluentui/react-icons';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchStructures } from 'src/tools/API';
import { DS } from 'src/constants';
import {
    Services_Utilities_Module_Reducer_Values,
    storeOpenTreeItems,
    storeSelectedStructureKey,
} from 'src/Chapters/Services/Utilities/reducer';

import { StyledBranch, StyledLeaf, StyledTreeItemLayout } from './styles';

interface Props {}

export const Tree: React.FC<Props> = () => {
    const { selectedStructureKey, openTreeItems } = useSelector(Services_Utilities_Module_Reducer_Values);
    const dispatch = useDispatch();

    const { data } = useFetchStructures();
    const handleOpenChange = (event: TreeOpenChangeEvent, data: TreeOpenChangeData) => {
        dispatch(
            storeOpenTreeItems(
                data.open
                    ? [...openTreeItems, data.value.toString()]
                    : openTreeItems.filter((value) => value !== data.value.toString()),
            ),
        );
    };

    const structureByType = {};
    Object.entries(data?.data ?? {}).forEach(([key, value]) => {
        const type = key.split('/')[2];

        if (!structureByType[type]) {
            structureByType[type] = { [key.split('/')[3]?.replace(/\.json$/i, '')]: value };
        } else {
            structureByType[type] = { ...structureByType[type], [key.split('/')[3]?.replace(/\.json$/i, '')]: value };
        }
    });

    const handleStructureClick = (key: string) => () => {
        dispatch(storeSelectedStructureKey(key));
    };

    return (
        <FluentTree openItems={openTreeItems} onOpenChange={handleOpenChange}>
            {Object.entries(structureByType).map(([key, value]) => {
                const isOpen = openTreeItems.includes(key);

                return (
                    <TreeItem itemType="branch" key={key} value={key}>
                        <StyledTreeItemLayout
                            expandIcon={isOpen ? <SubtractSquare16Regular /> : <AddSquare16Regular />}
                            isOpen={isOpen}
                        >
                            {key}
                        </StyledTreeItemLayout>
                        <StyledBranch isOpen={isOpen}>
                            <FluentTree>
                                {Object.entries(value as Object).map(([k, v]) => {
                                    return (
                                        <TreeItem itemType="leaf" key={k} value={k}>
                                            <StyledLeaf
                                                selected={!!selectedStructureKey?.includes(k)}
                                                onClick={handleStructureClick(`${key}${DS}${k}`)}
                                            >
                                                <TableFreezeColumnRegular style={{ marginRight: 8, fontSize: 20 }} />
                                                {k}
                                            </StyledLeaf>
                                        </TreeItem>
                                    );
                                })}
                            </FluentTree>
                        </StyledBranch>
                    </TreeItem>
                );
            })}
        </FluentTree>
    );
};
