import React, { useState, useMemo, useDeferredValue } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { SeriesHeatmapOptions, Tooltip, TooltipFormatterContextObject } from 'highcharts';

import HeatMap from 'src/components/Charts/HeatMap/HeatMap';
import { useGeneralSelector } from 'src/hooks';
import { emptyArray } from 'src/tools';
import { theme } from 'src/theme';

import { SensorData } from '../PcSensorsInfo/interfaces';

import { ModalWrapper, ChartWrapper } from './styles';

export type SensorsHeatMapProps = {
    sensors: SensorData[];
    mode: string;
};

const CHART_MARGIN_TOP = 5;
const X_AXIS_LABELS_MARGIN = 100;
const SENSOR_WIDTH = 15;
const SENSOR_HEIGHT = 25;

export const SensorsHeatMap: React.FC<SensorsHeatMapProps> = ({ sensors, mode = 'full' }) => {
    const [_chartInstance, setChartInstance] = useState<Highcharts.Chart | null>(null);

    const { t } = useTranslation();

    const [problemSensors, xAxisValues] = useMemo(() => {
        const nonFullSensors = sensors
            .filter((sensor) => sensor.operation !== 'full')
            .map((sensor, index) => {
                return {
                    id: sensor.mac,
                    index,
                    data: sensor.values.map((val) => {
                        const y = val.v;
                        const formattedDateTime = DateTime.fromISO(val.t).toFormat('HH:mm:ss');
                        return { x: formattedDateTime, y };
                    }),
                };
            });

        const xCoordsMaxCount = Math.max(...nonFullSensors.map((sensor) => sensor?.data?.length));
        const xAxis = nonFullSensors.find((sensor) => sensor?.data?.length === xCoordsMaxCount);

        return [nonFullSensors, xAxis?.data || emptyArray];
    }, [sensors]);

    const options = useMemo(() => {
        return {
            chart: {
                type: 'heatmap',
                marginTop: CHART_MARGIN_TOP,
                marginBottom: X_AXIS_LABELS_MARGIN,
                plotBorderWidth: 1,
                height: problemSensors.length * SENSOR_HEIGHT + X_AXIS_LABELS_MARGIN + CHART_MARGIN_TOP,
            },

            title: { text: '' },
            xAxis: {
                categories: xAxisValues.map((d) => d.x),
                labels: { rotation: -70 },
                tickInterval: 2,
                title: { text: '' },
            },
            yAxis: {
                categories: problemSensors.map((sensor) => sensor.id),
                tickWidth: 1,
                tickInterval: 1,
                showLastLabel: true,
                title: { text: '' },
            },
            colorAxis: {
                min: 0,
                max: 1,
                minColor: theme.colors.accent,
                maxColor: theme.colors.success,
            },
            tooltip: {
                // format:
                //     '<b>MAC: {series.yAxis.categories.(point.y)}</b>  <br>' +
                //     '<b>Time: {series.xAxis.categories.(point.x)}</b>',
                formatter: function (this: TooltipFormatterContextObject, tooltip: Tooltip) {
                    const status = this.point.value === 1 ? t('Active') : t('Inactive');
                    return `
                        <b>${t('MAC')}: ${this.series.yAxis.categories[this.y!]}</b><br>
                        <b>${t('Time')}: ${this.series.xAxis.categories[this.point.x]}</b><br>
                        <b>${t(`Status`)}: ${status}</b>
                    `;
                },
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.primaryDark,
                borderWidth: 1,
                padding: 10,
            },

            legend: {
                enabled: false,
            },

            series: [
                {
                    type: 'heatmap',
                    pointWidth: 20,
                    name: t('Sensor Data'),
                    borderWidth: 1,
                    borderColor: theme.colors.secondaryLight,

                    data: problemSensors.reduce(
                        (acc, sensor) => [...acc, ...sensor.data.map((d, index) => [index, sensor.index, d.y])],
                        [],
                    ),
                    dataLabels: {
                        enabled: false,
                        color: theme.colors.secondaryDarker,
                    },
                } as SeriesHeatmapOptions,
            ],
        };
    }, [problemSensors, t, xAxisValues]);

    const customChartDimensions = useMemo(() => {
        if (mode === 'full') {
            return {
                width: xAxisValues.length * SENSOR_WIDTH,
                height: problemSensors.length * SENSOR_HEIGHT,
            };
        } else {
            return {
                width: xAxisValues.length * 15,
                height: 500,
            };
        }
    }, [problemSensors, xAxisValues, mode]);

    const windowSize = useMemo(
        () => ({
            width: customChartDimensions.width + 100,
            height: customChartDimensions.height + CHART_MARGIN_TOP + X_AXIS_LABELS_MARGIN + 200,
        }),
        [customChartDimensions],
    );

    return (
        <ModalWrapper>
            <ChartWrapper windowSize={windowSize}>
                <HeatMap
                    options={options}
                    customChartDimensions={customChartDimensions}
                    storeChartInstance={setChartInstance}
                />
            </ChartWrapper>
        </ModalWrapper>
    );
};
