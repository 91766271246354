import axios from 'axios';

import { getHeaders } from './getHeaders';

export const getAny = async (url: string, token?: string) => {
    try {
        const response = token
            ? await axios({
                method: 'GET',
                url,
                headers: getHeaders(token),
            })
            : await axios({
                method: 'GET',
                url,
            });

        return response;
    } catch (error) {
        return error;
    }
};
