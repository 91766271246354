import { FC, useState } from 'react';
import { IProps } from './interfaces';
import { Extension, FileName, Link, Title, Wrapper } from './styles';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../../components/Stack/Stack';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import IconButton from '../../../../components/IconButton/IconButton';
import { ArrowDownload24Filled, EyeFilled } from '@fluentui/react-icons';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { storeNewAlert } from '../../../../components/Alert/reducer';
import { useSendSimpleAnalytics } from '../../../../hooks/useAnalytics';
import { generalReducerValues } from '../../../../General.reducer';
import Modals from 'src/components/Modals/Modals';

/**
 * Модальное окно для загрузки файлов для загрузки файлов
 */
const FilesModal: FC<IProps> = ({ files }) => {
    const { currentModuleID } = useSelector(generalReducerValues);
    const sendAnalytics = useSendSimpleAnalytics();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [viewModalStatus, setViewModalStatus] = useState<{ show: boolean; file: string }>({
        show: false,
        file: '',
    });

    /**
     * Загрузка файлa, путем создания "a" и события "click" на них
     */
    const onFileDownload = (fileURL: string) => async () => {
        try {
            const response = await fetch(fileURL, {
                method: 'GET',
                headers: {
                    // Добавьте необходимые заголовки, если нужно
                },
            });

            if (!response.ok) {
                throw new Error('Ошибка при получении файла');
            }

            const blob = await response.blob();
            const [name, ext] = getFileNameAndExtension(fileURL);
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.${ext}`);

            // Добавляем ссылку в DOM и кликаем по ней
            document.body.appendChild(link);
            link.click();

            // Удаляем ссылку и освобождаем память
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);

            sendAnalytics(`report_DetailedByBills_downloaded`, 'reachGoal', currentModuleID);
        } catch (error) {
            console.error('Ошибка при скачивании файла:', error);
            // Обработка ошибки (например, показать уведомление пользователю)
        }
    };

    // Вариант с использованием <a> и атрибута download

    //   const onFileDownload = (fileURL: string) => () => {
    //     const [name, ext] = getFileNameAndExtension(fileURL);
    //     const link = document.createElement('a');
    //     link.href = fileURL;
    //     link.setAttribute('download', ${name}.${ext}); // Устанавливаем атрибут download
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //     sendAnalytics(report_DetailedByBills_downloaded, 'reachGoal', currentModuleID);
    // };

    const onFileView = (file: string) => () => {
        setViewModalStatus({ show: true, file });
    };

    const getFileNameAndExtension = (fileURL: string): [string, string] => {
        const fileNameWHost = decodeURI(fileURL).split('?')?.[0] || '';
        const splittedFileNameWHost = fileNameWHost.split('/');

        const fileNameWExtension = splittedFileNameWHost[splittedFileNameWHost.length - 1] as string | undefined;
        const splittedFileNameWExtension = fileNameWExtension?.split('.') || [];
        const name = splittedFileNameWExtension.slice(0, splittedFileNameWExtension.length - 1).join('.');
        const extension = splittedFileNameWExtension[splittedFileNameWExtension.length - 1];
        return [name, extension];
    };

    /**
     * Загрузка всех файлов одновременно
     */
    const onAllFilesDownload = () => {
        const promises: Promise<AxiosResponse<MediaSource>>[] = [];
        files.forEach((file) => promises.push(axios.get(file, { responseType: 'blob' })));

        function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        axios
            .all(promises)
            .then(async (responses) => {
                sendAnalytics(`report_DetailedByBills_download_all`, 'reachGoal', currentModuleID);
                for (const [index, response] of responses.entries()) {
                    const [name, extension] = getFileNameAndExtension(files[index] || '');

                    const href = window.URL.createObjectURL(response.data);
                    const anchorElement = document.createElement('a');
                    anchorElement.setAttribute('href', href);
                    anchorElement.setAttribute('download', `${name}.${extension}`);
                    document.body.appendChild(anchorElement);
                    anchorElement.click();
                    document.body.removeChild(anchorElement);
                    window.URL.revokeObjectURL(href);
                    await delay(300);
                }
            })
            .catch(() => {
                dispatch(
                    storeNewAlert({
                        type: 'error',
                        text: t(
                            'An error occurred while downloading files. Try downloading the necessary files one by one or reload the page!',
                        ),
                    }),
                );
            });
    };

    return (
        <Wrapper>
            <Stack justifyContent="space-between" alignItems="center">
                <Title>{t('Files')}</Title>
                <Link onClick={onAllFilesDownload}>{t('Download all')}</Link>
            </Stack>
            <Stack direction={'column'} gap={10} styles={{ marginTop: '10px' }}>
                {files.map((file) => {
                    const [name, extension] = getFileNameAndExtension(file);

                    return (
                        <Stack key={file} justifyContent="space-between">
                            <Stack styles={{ overflow: 'hidden' }} alignItems="center" gap={5}>
                                <Extension>{extension}</Extension>
                                <FileName>{name}</FileName>
                            </Stack>
                            <Stack styles={{ overflow: 'hidden' }} alignItems="center" gap={5}>
                                {extension.toLowerCase() === 'pdf' && (
                                    <Tooltip content={t('View')}>
                                        <IconButton icon={<EyeFilled />} size={32} onClick={onFileView(file)} />
                                    </Tooltip>
                                )}
                                <Tooltip content={t('Download')}>
                                    <IconButton
                                        icon={<ArrowDownload24Filled />}
                                        size={32}
                                        onClick={onFileDownload(file)}
                                    />
                                </Tooltip>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
            <Modals
                modalStatus={viewModalStatus}
                closeModal={() => setViewModalStatus({ show: false, file: '' })}
                title={getFileNameAndExtension(viewModalStatus.file)[0]}
            >
                <iframe
                    title={getFileNameAndExtension(viewModalStatus.file)[0]}
                    src={viewModalStatus.file}
                    width="600px"
                    height="900px"
                />
            </Modals>
        </Wrapper>
    );
};

export default FilesModal;
