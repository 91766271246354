import { useAppDispatch } from 'src/hooks/useSettings';
import ReactDataSheet from 'react-datasheet';
import CellRenderer from './components/CellRenderer/CellRenderer';
import { TableWrapper } from './styles';
import { useDeferredValue } from 'react';
import {
    Services_Utilities_Module_Reducer_Values,
    fillTable,
    updateTableData,
} from 'src/Chapters/Services/Utilities/reducer';
import { ICell } from 'src/Chapters/Services/Utilities/interfaces';
import { useSelector } from 'react-redux';

interface IProps {
    data: ICell[][];
    tableKey: string;
}

const Table: React.FC<IProps> = ({ data, tableKey }) => {
    const dispatch = useAppDispatch();
    const { dataToEdit, selectedFields } = useSelector(Services_Utilities_Module_Reducer_Values);

    const cellRenderer = (props: ReactDataSheet.CellRendererProps<ICell>) => {
        return <CellRenderer {...props} />;
    };

    const isAllCellsEmpty = (data: ICell[][]) => {
        return data.every((row) => row.every((cell) => cell.value === ''));
    };

    const onCellsChanged = (changes: ReactDataSheet.CellsChangedArgs<ICell>) => {
        tableKey && !isAllCellsEmpty(dataToEdit[tableKey]) && dispatch(updateTableData({ data: changes, tableKey }));
    };

    const defData = useDeferredValue(data);

    const handlePaste: ReactDataSheet.PasteParser = (str: string) => {
        const rows = str.split('\n').map((row) => row.split('\t'));
        const dataToFill: ICell[][] = rows.map((row, i) => {
            return row.map((cell, j) => {
                return {
                    value: cell,
                    readOnly: false,
                    row: i,
                    col: j,
                };
            });
        });
        dispatch(fillTable({ data: dataToFill, tableKey }));
        return rows;
    };

    return (
        <TableWrapper>
            <ReactDataSheet
                valueRenderer={(cell: any) => cell.value}
                onCellsChanged={onCellsChanged}
                cellRenderer={cellRenderer}
                data={defData}
                parsePaste={handlePaste}
            />
        </TableWrapper>
    );
};

export default Table;
