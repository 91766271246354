import { useMemo } from 'react';
import { DateTime } from 'luxon';

import { useUniversalDateTimePickerPeriod, useCommonMapsData } from 'src/hooks';
import { useFetchSensorsStatuses } from 'src/tools/API';
import { emptyArray } from 'src/tools';

import { SensorStatusByMarker } from '../interfaces';
import { describeSensor } from '../tools/describeSensor';

export const useCommonMapInfoWithSensors = () => {

    const periodFromTo = useUniversalDateTimePickerPeriod();
    const { plans, layers, locationId } = useCommonMapsData(periodFromTo);
    const {
        data: sensorsStatuses = emptyArray,
        isLoading,
        isError,
    } = useFetchSensorsStatuses({ locationId, ...periodFromTo });

    const sensorStatusesByMarker = useMemo(() => {
        const maxMeasurements = Math.max(...sensorsStatuses.map((item) => item.values.length));
        return sensorsStatuses.reduce(
            (acc, status) => ({
                ...acc,
                [status.ipointMarker]: {
                    status,
                    description: describeSensor(status.values, maxMeasurements),
                },
            }),
            {} as SensorStatusByMarker,
        );
    }, [sensorsStatuses]);

    const latestPlans = useMemo(() => {
        const result = {};

        Array.isArray(plans) &&
            plans?.forEach((plan) => {
                const mainPlan = plans?.filter((item) => plan.floor === item?.floor)?.filter((item) => item.isMain)[0];
                if (result[plan.floor]) {
                    if (
                        DateTime.fromISO(plan.activeFrom).toMillis() >=
                        DateTime.fromISO(result[plan.floor].activeFrom).toMillis()
                    ) {
                        result[plan.floor] = { ...plan, mainPlan };
                    }
                } else {
                    result[plan.floor] = { ...plan, mainPlan };
                }
            });
        return result;
    }, [plans]);

    return {
        periodFromTo,
        plans,
        latestPlans,
        layers,
        sensorsStatuses,
        sensorStatusesByMarker,
        isLoadingSensors: isLoading,
        isErrorSensors: isError,
    };
};