import React, { RefObject, createRef, useEffect, useMemo, useRef, useState } from 'react';
import { Stage, Layer, Group } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { Stage as IStage } from 'konva/lib/Stage';
import { KonvaEventObject } from 'konva/lib/Node';
import { useTranslation } from 'react-i18next';

import { useGeneralSelector } from 'src/hooks';
import LoadingBox from 'src/components/LoadingBox/LoadingBox';
// import { useGeneralSelector, useUniversalDateTimePickerPeriod, useCommonMapsData } from 'src/hooks';
// import { MapLayerType, FpcSensorStatus } from 'src/tools/API';
// import { mlFromLocation, uniqueArray, emptyObject, emptyArray } from 'src/tools';
// import { checkRequiredFields } from 'src/tools/Arrays/checkRequiredFields';
// import { ISelectedOption } from 'src/components/Selects/Select/interfaces';
// import { Identity } from 'src/types';
// import { mlFromLocation, getDatesFromPeriod } from 'src/tools';

import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { FPC_Monitoring_Module_Reducer_Values, storeSelectedMarker, storeOverMarker } from '../../../../reducer';
import { geoMatrixConverner } from '../../core/geoMatrixConverter';
import { handleWheelScale } from '../../core/handleWheelScale';
import { fitStage } from '../../core/fitStage';
import UrlImage from '../UrlImage/UrlImage';
import Geometry from '../Geometry/Geometry';
// import { describeSensor, PcSensorDescription } from '../../tools/describeSensor';
import { useCommonMapInfoWithSensors } from '../../hooks/useCommonMapInfoWithSensors';
// import { SensorStatusByMarker } from '../../interfaces';

import { Props } from './interfaces';
import { Cont1, Floor, Overlay, StageWrapper, StageWrapperJoin, Wrapper } from './styles';


const DEFAULT_PERIOD = { dateFrom: undefined, dateTo: undefined };

const Floors: React.FC<Props> = ({ canvasAreaSize, scale, changeScale }) => {
    const stageRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    const refsOfStages = useRef([]);

    // const [widgetSettings, setWidgetSettings] = useState<string[]>([]);
    // const [stageHeight, setStageHeight] = useState(1);
    // const [stageWidth, setStageWidth] = useState(1);

    const dispatch = useDispatch();
    const {
        selectedMarker,
        selectedGroupId,
        colorsByMarker,
        zonesByGroup,
        selectedZoneId,
        overMarker,
        selectedRelationId,
        // metricsData,
        selectedPlaceId,
    } = useSelector(FPC_Monitoring_Module_Reducer_Values);

    const {
        cfg: { reportingObjectsByType, reportingObjectsByTypeAndMarker },
        allMetrics,
        mainAreaSize,
        currentModuleID,
    } = useGeneralSelector();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const { latestPlans, isLoadingSensors, isErrorSensors } = useCommonMapInfoWithSensors();
    // const { locations, selectedLocationId } = useGeneralSelector();
    // const location = locations?.find((item) => item.id === selectedLocationId);
    // const mlId = mlFromLocation(location);
    // const periodFromTo = useUniversalDateTimePickerPeriod();
    // const { plans, layers, locationId } = useCommonMapsData(periodFromTo);
    // const {
    //     data: sensorsStatuses = emptyArray,
    //     isLoading,
    //     isError,
    // } = useFetchSensorsStatus({ locationId, ...periodFromTo });
    // // const sensorsStatuses = emptyArray as FpcSensorStatus[];
    // const sensorStatusesByMarker = useMemo(() => {
    //     const maxMeasurements = Math.max(...sensorsStatuses.map((item) => item.values.length));
    //     return sensorsStatuses.reduce(
    //         (acc, status) => ({
    //             ...acc,
    //             [status.ipointMarker]: {
    //                 status,
    //                 description: describeSensor(status.values, maxMeasurements),
    //             },
    //         }),
    //         {} as SensorStatusByMarker,
    //     );
    // }, [sensorsStatuses]);
    // const layersByType = useMemo(
    //     () => layers.reduce(
    //         (acc, layer) => ({
    //             ...acc
    //             [layer.layerType]: [...(acc[layer.layerType] || []), layer],
    //         }),
    //         {} as Record<MapLayerType, MapLayer[]>,
    //     ),
    //     [layers],
    // );
    // console.log(layers)

    // const sensors = layers.filter<>(({ layerType }) => layerType === 'pc_sensor_ipoints_layer')
    // const res = sensorsObjects = sensorsLayers.map(
    //     (layer) => (layer.data)
    // )

    // const visibleLayersSettings = (localCurrentOptions?.['mapsSettings'] || emptyArray) as ISelectedOption[];
    // const visibleLayerTypes = useMemo(
    //     () => visibleLayersSettings.map(({ section }) => section as MapLayerType).filter(Boolean),
    //     [visibleLayersSettings],
    // );
    // console.log(mapSettings)

    // const stageRefWidth = stageRef?.current?.offsetWidth || 1;
    // const stageRefHeight = stageRef?.current?.offsetHeight || 1;

    // interface T1 = {
    //     field1: string,
    //     field2: string,
    // };

    // interface T2 = {
    //     field1: string,
    //     field2: string,
    //     field3?: string,
    // }

    // const

    // const func = (data: {)
    // const [widgetSettings, stageWidth, stageHeight] = useMemo(
    //     () => {
    //         const height = mapSettings.hasOwnProperty('joinFloors')
    //             ? canvasAreaSize.height - 300
    //             : stageRefHeight;
    //         // const settingIds = mapSettings.map((item: { id: string }) => item.id);
    //         const settingIds = [] as string[];
    //         return [settingIds, stageRefWidth, height];
    //     },
    //     [
    //         mapSettings,
    //         stageRefWidth,
    //         stageRefHeight,
    //     ],
    // );
    const [widgetSettings, setWidgetSettings] = useState<string[]>([]);
    const [stageHeight, setStageHeight] = useState(1);
    const [stageWidth, setStageWidth] = useState(1);

    useEffect(() => {
        const width = stageRef?.current?.offsetWidth || 1;
        setStageWidth(width);
    }, [stageRef?.current?.offsetWidth, widgetSettings]);

    useEffect(() => {
        if (localCurrentOptions?.['mapsSettings']) {
            setWidgetSettings(localCurrentOptions?.['mapsSettings'].map((item: { id: string }) => item.id));
        }
    }, [localCurrentOptions?.['mapsSettings']]);

    useEffect(() => {
        if (widgetSettings.includes('joinFloors')) {
            setStageHeight(canvasAreaSize.height - 300);
        } else {
            setStageHeight(stageRef?.current?.offsetHeight || 1);
        }
    }, [widgetSettings, canvasAreaSize, stageRef?.current?.offsetHeight]);

    const widestPlan = useMemo(() => {
        return Object.keys(latestPlans)
            .map((key) => latestPlans[key])
            .sort((a, b) => b.width / b.scale - a.width / a.scale)[0];
    }, [latestPlans]);

    // const metricsNumber = localCurrentOptions?.['selectedMetrics']?.length
    //     ? localCurrentOptions?.['selectedMetrics']?.length
    //     : 1;

    // const stageWidth = widgetSettings.includes('showRatings')
    //     ? (0.7 * canvasAreaSize.width) / metricsNumber
    //     : canvasAreaSize.width / metricsNumber;

    const finalPlans = Object.keys(latestPlans)
        .map((key) => {
            const plan = latestPlans[key];
            let imageOffset = [0, 0];
            const vector = geoMatrixConverner(0, 0, plan.plan2geo);
            if (vector && vector[0]) {
                imageOffset = geoMatrixConverner(vector[0], vector[1], widestPlan.mainPlan.geo2plan).map((item) => {
                    if (Math.abs(item) < 0.1) {
                        return 0;
                    } else {
                        return item;
                    }
                });
            }
            return { ...plan, imageOffset, widestPlan };
        })
        .sort((a, b) => b.floor - a.floor);
    useEffect(() => {
        let stageScale = fitStage(widestPlan, stageWidth);

        if (isNaN(stageScale)) stageScale = 1;
        changeScale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    }, [widestPlan, stageWidth]);

    useEffect(() => {
        // add or remove refs
        refsOfStages.current = Array(finalPlans.length)
            .fill(null)
            .map((_, i) => refsOfStages.current[i] || createRef());
    }, [finalPlans]);

    const selectObject = (marker: string) => {
        // dispatch(storeSelectedMarker(marker));
    };

    const onMouseOverLeave = (marker: string | null) => {
        dispatch(storeOverMarker(marker));
    };

    /** Функция для изменения курсора на холсте */
    const changeStageCursor = (stageIndex: number, cursor: string) => () => {
        const container = (refsOfStages.current![stageIndex] as IStage | null)?.container();
        if (container) {
            container.style.cursor = cursor;
        }
    };

    const overlay = useMemo(() => {
        if (isLoadingSensors) {
            return (
                <Overlay>
                    <LoadingBox height={400} text={t('Loading sensors status...')}></LoadingBox>
                </Overlay>
            );
        } else if (isErrorSensors) {
            return (
                <Overlay>
                    <LoadingBox height={400} isError text={''}></LoadingBox>
                </Overlay>
            );
        } else {
            return null;
        }
    }, [isLoadingSensors, isErrorSensors]);

    const geometry = (plan: any, planIndex = 0) => {
        const showLabels = widgetSettings.includes('showLabels');

        let passwayRelationShapeMarker: string | null = null;

        if (selectedRelationId === 'place' && selectedPlaceId) {
            passwayRelationShapeMarker = reportingObjectsByType['place']?.filter(
                (item) => String(item.id) === String(selectedPlaceId),
            )[0].marker;
        } else if (selectedRelationId === 'zone' && selectedGroupId) {
            passwayRelationShapeMarker = zonesByGroup[selectedGroupId]?.filter(
                (item) => String(item.id) === String(selectedZoneId),
            )[0].marker;
        }

        // const layerType  from select
        // always display pc_isensor
        return (
            <Geometry
                // visibleLayerTypes={visibleLayerTypes}
                overMarker={overMarker}
                passwayRelationShapeMarker={passwayRelationShapeMarker}
                showLabels={showLabels}
                selectedRelationId={selectedRelationId}
                plan={plan}
                colorsByMarker={colorsByMarker}
                selectObject={selectObject}
                onMouseOverLeave={onMouseOverLeave}
                allMetrics={allMetrics}
                selectedMarker={selectedMarker}
                selectedGroupId={selectedGroupId}
                widgetSettings={widgetSettings}
                stageScale={scale.stageScale}
                reportingObjectsByTypeAndMarker={reportingObjectsByTypeAndMarker}
                stageIndex={planIndex}
                changeStageCursor={changeStageCursor}
            />
        );
    };

    const onStageDblClick = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        let stageScale = fitStage(widestPlan, stageWidth, e);

        if (isNaN(stageScale)) stageScale = 1;
        changeScale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const onStageClick = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        dispatch(storeSelectedMarker(null));
    };

    const onStageWheel = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        changeScale(handleWheelScale(e));
    };

    let y = 0;

    return (
        <Wrapper isRatingShown={widgetSettings.includes('showRatings')} ref={stageRef}>
            {widgetSettings.includes('joinFloors') ? (
                <StageWrapperJoin height={mainAreaSize.height - 300}>
                    <Stage
                        ref={(el) => ((refsOfStages.current![0] as IStage | null) = el)}
                        width={stageWidth}
                        height={stageHeight}
                        draggable
                        scaleX={scale.stageScale}
                        scaleY={scale.stageScale}
                        x={scale.stageX}
                        y={scale.stageY}
                        onWheel={onStageWheel}
                        onClick={onStageClick}
                        onDblClick={onStageDblClick}
                    >
                        {finalPlans.map((plan, i, arr) => {
                            if (i !== 0) {
                                y =
                                    y +
                                    arr[i - 1].height * (arr[i - 1].widestPlan.mainPlan.scale / arr[i - 1].scale) +
                                    arr[i - 1].imageOffset[1] * (arr[i - 1].mainPlan.scale / arr[i - 1].scale);
                            } else {
                                y = y + plan.imageOffset[1] * (plan.mainPlan.scale / plan.scale);
                            }

                            return (
                                <Layer y={y} id={`imageLayer` + i} draggable={false} key={plan.floor - i}>
                                    <Group key={'YYY' + i} draggable={false}>
                                        {widgetSettings.includes('showPlans') && (
                                            <UrlImage
                                                src={plan.image}
                                                planBlueprintScale={plan.widestPlan.mainPlan.scale / plan.scale}
                                                imageOffset={plan.imageOffset}
                                            />
                                        )}
                                        {geometry(plan)}
                                    </Group>
                                </Layer>
                            );
                        })}
                    </Stage>
                </StageWrapperJoin>
            ) : (
                <Cont1>
                    {finalPlans.map((plan, i, arr) => {
                        const planRatio = plan.width / plan.height;
                        const stageHeight = Math.round(stageWidth / planRatio);

                        return (
                            <StageWrapper key={`canvas${i}${plan.floor}`}>
                                {overlay}
                                <Floor>{plan.floor}</Floor>
                                <Stage
                                    ref={(el) => ((refsOfStages.current![i] as IStage | null) = el)}
                                    width={stageWidth}
                                    height={stageHeight}
                                    draggable
                                    scaleX={scale.stageScale}
                                    scaleY={scale.stageScale}
                                    x={scale.stageX}
                                    y={scale.stageY}
                                    onWheel={onStageWheel}
                                    onClick={onStageClick}
                                    onDblClick={onStageDblClick}
                                    id={`canvas${i}`}
                                >
                                    <Layer id={`imageLayer` + i} draggable={false}>
                                        <Group key={'YYY' + i} draggable={false}>
                                            {widgetSettings.includes('showPlans') && (
                                                <UrlImage
                                                    src={plan.image}
                                                    planBlueprintScale={plan.widestPlan.mainPlan.scale / plan.scale}
                                                    imageOffset={plan.imageOffset}
                                                />
                                            )}
                                            {geometry(plan, i)}
                                        </Group>
                                    </Layer>
                                </Stage>
                            </StageWrapper>
                        );
                    })}
                </Cont1>
            )}
        </Wrapper>
    );
};

export default Floors;
