import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from 'src/General.reducer';
import axios from 'axios';
import { IBgReport } from 'src/General.interfaces';
import bgReportsTableDataAdapter from '../components/HistoryTable/dataAdapters/bgReportsTableDataAdapter';
import { ANTIFRAUD_REPORTS_TYPE, GET_ALL_ANTIFRAUD_REPORTS_RQ_KEY } from 'src/constants/constants';
import { DateTime } from 'luxon';
import { getHeaders } from 'src/tools/API/getHeaders';

/**
 * Кастомный хук для получения данных с сервера
 */
export const useFetchHistory = () => {
    const {
        token,
        urlsByServices,
        selectedLocationId,
        lang,
        backgroundReportTypesById,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const bgReportUrl = useMemo(() => {
        if (urlsByServices) {
            return urlsByServices['app/app-backend'].BACKGROUND_REPORTS_URL;
        }
        return null;
    }, [urlsByServices]);

    const [queryKey, queryFn, dataAdapter] = useMemo(() => {
        if (token && bgReportUrl && selectedLocationId) {
            return [
                [GET_ALL_ANTIFRAUD_REPORTS_RQ_KEY, token, ANTIFRAUD_REPORTS_TYPE, selectedLocationId],
                () => {
                    return axios
                        .get<IBgReport[]>(
                            `${bgReportUrl}?report_type=${ANTIFRAUD_REPORTS_TYPE}&pl_id=${selectedLocationId}`,
                            {
                                headers: getHeaders(token),
                            },
                        )
                        .then((response) => response.data);
                },
                (response: IBgReport[]) =>
                    bgReportsTableDataAdapter({
                        reports: response,
                        lang,
                        backgroundReportTypesById,
                        reportingObjectsById,
                    }),
            ];
        }
        return [[]];
    }, [token, bgReportUrl, selectedLocationId, lang, backgroundReportTypesById, reportingObjectsById]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
        refetchInterval: 120000,
        retry: 2,
    });
};
