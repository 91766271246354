import { useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LoadingBox from 'src/components/LoadingBox/LoadingBox';
import { MapPcISensorLayer } from 'src/tools/API';
import { emptyArray } from 'src/tools';
import { FPC_Monitoring_Module_Reducer_Values } from 'src/Chapters/Services/FpcMonitoring/reducer';
import { OPERATION_ORDER } from 'src/Chapters/Services/FpcMonitoring/constants';
import { OperationsTypes } from 'src/Chapters/Services/FpcMonitoring/interfaces';
import { structuresComparer } from 'src/tools';

import { useCommonMapInfoWithSensors } from '../../hooks/useCommonMapInfoWithSensors';
import { Summary } from '../Summary';

import { ListContainer, StatusCircle, StatusText, ListItem } from './styles';

export const PcSensorsInfo = () => {
    const { t } = useTranslation();
    const { layers, sensorStatusesByMarker, isLoadingSensors, isErrorSensors } = useCommonMapInfoWithSensors();

    const { selectedSensorMarker } = useSelector(FPC_Monitoring_Module_Reducer_Values);

    const scollToRef = useRef<HTMLLIElement>(null);

    const sensorsData = useMemo(() => {
        const sensorLayers = (layers ?? emptyArray)
            .filter(({ layerType }) => layerType === 'pc_sensor_ipoints_layer')
            .reduce(
                (acc, layer) => [
                    ...acc,
                    ...((layer.data ?? emptyArray) as MapPcISensorLayer[]).map((data) => ({
                        ...data,
                        floor: layer.floor,
                    })),
                ],
                emptyArray,
            );
        return sensorLayers.reduce((acc, layer) => {
            const state = sensorStatusesByMarker[layer.marker];
            if (!state) {
                return acc;
            }
            const sensorInfo = {
                id: state.status.sensorId,
                marker: state.status.ipointMarker,
                mac: state.status.sensorSerial,
                type: state.status.sensorType,
                floor: layer.floor,
                values: state.status.values,
                operation: state.description.operation as OperationsTypes,
                fill: state.description.fill,
            };
            return [...acc, sensorInfo];
        }, emptyArray);
    }, [layers, sensorStatusesByMarker]);

    const comparer = structuresComparer(OPERATION_ORDER)

    const sortedSensorsData = (sensorsData || emptyArray).sort((a, b) => comparer(a.operation, b.operation));

    const fetchStatus = useMemo(() => {
        if (isLoadingSensors) {
            return (
                <LoadingBox height={400} text={t('Loading sensors status...')}></LoadingBox>
            );
        } else if (isErrorSensors) {
            return (
                <LoadingBox height={400} isError text={''}></LoadingBox>
            );
        } else {
            return null;
        }
    }, [isLoadingSensors, isErrorSensors, t]);

    useEffect(() => {
        scollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, [scollToRef, selectedSensorMarker]);
    const isDisplayFetchStatus = isLoadingSensors || isErrorSensors;
    return (isDisplayFetchStatus
        ? fetchStatus
        : (
            <div>
                <ListContainer>
                    {sortedSensorsData.map((sensorInfo, index) => (
                        <ListItem
                            key={index}
                            {...(selectedSensorMarker === sensorInfo.marker) && {
                                isSelected: true,
                                ref: scollToRef,
                            }}
                        >
                            <StatusCircle fill={sensorInfo.fill} />
                            <StatusText>{sensorInfo.id}</StatusText>
                            <StatusText>{sensorInfo.floor}</StatusText>
                            <StatusText>{sensorInfo.mac}</StatusText>
                        </ListItem>
                    ))}
                </ListContainer>
                {!!sensorsData?.length && (<Summary sensorsData={sensorsData}/>)}
            </div>
        )
    );
};
