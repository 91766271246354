import { DateTime } from 'luxon';

type TRange =
    | {
          date_to: string;
          date_from: string;
      }
    | {
          dateTo: string;
          dateFrom: string;
      };

/**
 * Функция для фильтрации массива данных по переданному периоду
 * @param relation массив объектоа, который нужно отфильтровать по дате
 * @param period пириод, по которуму фильтровать
 * @returns Отфильтрованный маассив
 */
export function filterValidDateRanges<T extends TRange>(
    relation: Array<T>,
    period: { dateFrom: string; dateTo: string } | undefined,
    includeNonPeriodItems?: boolean,
) {
    return relation?.filter((item) => dateRangesFilter(item, period, includeNonPeriodItems));
}

export function dateRangesFilter<T extends TRange>(
    item: T,
    period: { dateFrom: string; dateTo: string } | undefined,
    includeNonPeriodItems: boolean = false,
): boolean {
    if ('date_from' in item && 'date_to' in item) {
        return (
            DateTime.fromISO(item.date_from).toMillis() <= DateTime.fromISO(period?.dateTo || '').toMillis() &&
            DateTime.fromISO(item.date_to).toMillis() >= DateTime.fromISO(period?.dateFrom || '').toMillis()
        );
    }

    if ('dateFrom' in item && 'dateTo' in item) {
        return (
            DateTime.fromISO(item.dateFrom).toMillis() <= DateTime.fromISO(period?.dateTo || '').toMillis() &&
            DateTime.fromISO(item.dateTo).toMillis() >= DateTime.fromISO(period?.dateFrom || '').toMillis()
        );
    }

    return includeNonPeriodItems;
}
