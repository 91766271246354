import { TreeItemLayout } from '@fluentui/react-components';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';

export const StyledTreeItemLayout = styled(TreeItemLayout)<{ isOpen: boolean }>`
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &:hover {
        background-color: ${theme.colors.secondary};
    }
`;

export const StyledBranch = styled.div<{ isOpen: boolean }>`
    overflow: hidden;
    max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: max-height 0.5s ease, opacity 0.5s ease;
    margin-left: 20px;
    padding-left: 10px;
    border-left: 1px dashed ${theme.colors.secondary};
`;

export const StyledLeaf = styled.div<{ selected: boolean }>`
    padding: 5px 10px;
    margin: 4px 0;
    /* font-size: 14px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: ${({ selected }) => (selected ? theme.colors.secondaryDark : '#ffffff')};

    &:hover {
        background-color: ${({ selected }) => (selected ? theme.colors.secondaryDark : theme.colors.secondaryLight)};
    }
`;
