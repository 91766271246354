import styled from 'styled-components/macro';

export const MetricContainer = styled.div`
    margin-top: var(--space-xxs);
    display: flex;
    gap: 10px;
`;

export const CanvasWrapper = styled.div`
    /* display: flex; */
`;

export const Overlay = styled.div`
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
`;

/* width: ${(p) => (p.isRatingShown ? '70%' : '100%')}; */
export const Cont = styled.div<{ isRatingShown?: boolean }>`
    min-width: 120px;
    flex: 1 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    /* padding: 10px; */
    gap: 10px;
`;

export const Floor = styled.div`
    position: absolute;
    width: var(--space-md);
    height: var(--space-md);
    background-color: var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 2;
    font-weight: 500;
`;

export const StageWrapper = styled.div`
    cursor: pointer;
    position: relative;
    /* border: 1px solid var(--color-secondary-dark); */
    /* padding: 10px; */
    margin-bottom: 10px;
    background-color: var(--color-white);
`;

export const ControlPanelHeader = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`;

export const ControlPanelBody = styled.div`
    padding: 10px;
    padding-top: 0;

`;

export const ControlPanelWrapper = styled.div<{ height?: number}>`
    height: ${(p) => {
        return p?.height ? p?.height + 'px' : '100%';
    }};
    width: 320px;
    padding: var(--space-xxxs);
    overflow: auto;

    border: 1px solid var(--color-border-gray);
    border-radius: 3px;
    margin-bottom: 8px;
    background-color: var(--color-white);
    cursor: pointer; // Add cursor pointer to indicate clickable
`;