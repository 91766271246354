import { FC } from 'react';
import ReactDataSheet from 'react-datasheet';
import { omit } from 'lodash';
import { Td } from './styles';

import { ICell } from 'src/Chapters/Services/Utilities/interfaces';

const CellRenderer: FC<ReactDataSheet.CellRendererProps<ICell>> = (props) => {
    const modifiedProps = {
        ...omit(props, ['cell', 'updated', 'editing', 'selected', 'attributesRenderer']),
        style: {
            ...(props.style || {}),
            background: 'transparent',
        },
    };

    return <Td {...modifiedProps}>{props.children}</Td>;
};

export default CellRenderer;
