import { userSettingsReducerValues, changeOptions } from '../../UserSettings/reducer';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { IProps } from './interfaces';
import { shortStringDate } from '../../../tools/Strings/shortStringDate';
import Button from '../../Button/Button';
import Modals from '../../Modals/Modals';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import { CalendarLtr20Regular } from '@fluentui/react-icons';
import { useAppDispatch } from '../../../hooks/useSettings';

import { DatePickerButton, Label, Wrapper } from './styles';
import { useWidgetCurrentOptions } from 'src/hooks';
import { DateTime } from 'luxon';

const UniversalDatePicker: React.FC<IProps> = ({
    oneDayOnly = false,
    yearsOnly = false,
    labelText = ' ',
    name,
    showFutureDates = false,
}) => {
    const [modalStatus, setModalStatus] = useState({ show: false });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { lang, selectedLocationId, currentModuleID } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    useEffect(() => {
        if (
            localCurrentOptions &&
            !localCurrentOptions?.[name] &&
            localCurrentOptions?.currentModuleID === currentModuleID
        ) {
            dispatch(
                changeOptions({
                    newOptions: {
                        [name]: [
                            { id: 'dateFrom', text: DateTime.now().toISODate() },
                            { id: 'dateTo', text: DateTime.now().toISODate() },
                        ],
                    },
                }),
            );
        }
    }, [currentModuleID, localCurrentOptions, name]);

    const onClick = () => {
        setModalStatus({ show: true });
    };

    const closeModal = () => {
        setModalStatus({ show: false });
    };

    const text = currentOptions?.[name]?.[0]
        ? shortStringDate([currentOptions[name][0].text, currentOptions[name][1].text], lang)
        : t('Select date(s)');

    const handleDateRangeChange = (period: { dateFrom: string; dateTo: string }) => {
        selectedLocationId &&
            dispatch(
                changeOptions({
                    newOptions: {
                        [name]: [
                            { id: 'dateFrom', text: period.dateFrom },
                            { id: 'dateTo', text: period.dateTo },
                        ],
                    },
                }),
            );
        setModalStatus({ show: false });
    };

    return (
        <Wrapper>
            <Label>{labelText}</Label>
            <DatePickerButton appearance="outline" text={text} onClick={onClick} icon={<CalendarLtr20Regular />} />
            <Modals title="" modalStatus={modalStatus} closeModal={closeModal}>
                <DateRangePicker
                    handleDateRangeChange={handleDateRangeChange}
                    oneDayOnly={oneDayOnly}
                    yearsOnly={yearsOnly}
                    showFutureDates={showFutureDates}
                />
            </Modals>
        </Wrapper>
    );
};

export default UniversalDatePicker;
