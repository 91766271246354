import { useDeferredValue, useMemo } from 'react';
import { useFetchStructures } from 'src/tools/API';
import { DS } from 'src/constants';
import { FocusTable } from 'src/components/FocusTable';
import generateId from 'src/tools/generateId';
import { Services_Utilities_Module_Reducer_Values } from 'src/Chapters/Services/Utilities/reducer';
import { useSelector } from 'react-redux';

type TableProps = {};

export const Table: React.FC<TableProps> = () => {
    const { data } = useFetchStructures();
    const { selectedStructureKey } = useSelector(Services_Utilities_Module_Reducer_Values);

    const tableData = useMemo(() => {
        if (!data || !selectedStructureKey) return [];
        return Object.entries(data.data as Record<string, Array<Record<string, string | number>>>).find(
            ([key]) =>
                key.includes(selectedStructureKey.split(DS)[0]) && key.includes(selectedStructureKey.split(DS)[1]),
        )?.[1] as Array<{ id: string }> | [];
    }, [data, selectedStructureKey]);

    function stringifyNestedObjects(input: Record<string, any>): Record<string, string | number> {
        const result: Record<string, string | number> = {};

        for (const [key, value] of Object.entries(input)) {
            if ((typeof value === 'object' || Array.isArray(value)) && value !== null) {
                // Если значение - объект (но не массив), преобразуем в строку
                result[key] = JSON.stringify(value);
            } else {
                // Оставляем как есть
                result[key] = value;
            }
        }

        return result;
    }

    const columnsOptions = useMemo(() => {
        if (!tableData || tableData.length === 0) return [];
        return Object.keys(tableData[0]).map((key) => ({ id: key, label: key }));
    }, [tableData]);

    const defData = useDeferredValue(
        tableData.map((item) => stringifyNestedObjects(item)).map((item) => ({ ...item, id: generateId() })),
    );

    return selectedStructureKey ? (
        <FocusTable
            columnsOptions={columnsOptions}
            componentId="structureTable"
            nameForExports={selectedStructureKey ?? ''}
            value={defData}
        />
    ) : null;
};
